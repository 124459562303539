@media only screen and (max-width: 600px) {
  .menuBarTop {
    max-width: 100px;
  }
}

@media only screen and (min-width: 600px) {
  .menuBarTop {
    width: 360px;
  }
}
