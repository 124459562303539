@import "~antd/dist/antd.less";

body {
  font-family: "Besley", serif;
}

#root {
  height: 100%;
}

@primary-color: #307097;